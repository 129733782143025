<template>
  <div
    v-loading="loading"
    class="bordered"
  >
    <el-row :gutter="20">
      <el-col
        :xs="12"
        :md="6"
      >
        <div class="form-control">
          <label>
            Audience Name
          </label>
          <el-input
            v-model="name"
          />
        </div>
        <div class="form-control">
          <label>
            Organization
          </label>
          <org-picker :organization.sync="organization" />
        </div>
        <div class="form-control">
          <label>Date Range</label>
          <date-picker
            :date-range.sync="dateRange"
            :organization="organization"
          />
        </div>
        <div class="form-control">
          <label>
            Tags
          </label>
          <tag-picker :tags.sync="tags" />
        </div>
        <div>
          <el-button
            :disabled="cannotUpload"
            type="primary"
            @click="handlePlaceRequest"
          >
            Place Request
          </el-button>
        </div>
      </el-col>
      <el-col
        style="position: relative"
        :xs="12"
        :md="6"
      >
        <uploader
          ref="uploader"
          :accept-file-exts="fileExts"
          api-endpoint="/api/v1/autopolygons"
          single-file
          upload-field-name="imports"
          @complete="uploadComplete"
          @file-change="handleFileChange"
          @upload-state-change="handleStateChange"
        />
      </el-col>
      <el-col
        :xs="24"
        :md="12"
        class="help-text"
      >
        <p>Please provide a .csv file in the following format:</p>
        <p><pre>location name,address,city,state,zip,zip-4</pre></p>
        <p><strong>Guidelines:</strong> State must be the two-character abbreviation, ie: NY not New York. The 'zip-4' field is optional and can be left blank. File must be in .csv UTF-8 format.</p>
        <p>
          Please download <a :href="autopolygonTemplateUri">the CSV template</a>
          for an example.
        </p>
      </el-col>
    </el-row>
    <csv-problems
      :headers="['address', 'city', 'state', 'zip']"
      :problems="problems"
      :visible="showProblems"
      @close="() => showProblems = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getSignedS3Url } from '../../methods/aws';
import { dateToString, isUTF8 } from '../../helpers';
import CsvProblems from '../global/CsvProblems';
import DatePicker from '../global/DatePicker.vue';
import OrgPicker from '../global/OrgPicker';
import Uploader from '../global/Uploader.vue';
import TagPicker from '../global/TagPicker';
import papa from 'papaparse';

const ADDRESS_LIMIT = 10000;

export default {
  components: {
    CsvProblems,
    DatePicker,
    OrgPicker,
    TagPicker,
    Uploader,
  },

  data() {
    return {
      loading: false,
      dateRange: [],
      errors: [],
      fileList: [],
      fileExts: ['.csv'],
      name: '',
      organization: this.$store.state.user.orgDetails,
      problems: [],
      radius: 1,
      radiusFeet: true,
      radiusOptions: [
        { feet: 90, meters: 30 },
        { feet: 165, meters: 50 },
        { feet: 245, meters: 75 },
        { feet: 325, meters: 100 },
        { feet: 650, meters: 200 },
      ],
      showProblems: false,
      tags: [],
    };
  },

  computed: {
    ...mapGetters('settings', [
      'autopolygonTemplateUri',
    ]),
    cannotUpload() {
      return this.name === '' || this.fileList.length === 0 || this.dateRange.length !== 2;
    },
  },

  methods: {
    downloadTemplate() {
      getSignedS3Url
        .callPromise({
          uri: this.$store.state.settings.autopolygonTemplateUri,
        })
        .then(url => {
          window.location.href = url;
        })
        .catch(err => this.$reportError(err));
    },

    async handleFileChange(fileList) {
      this.loading = true;
      const utf8 = await isUTF8(fileList[0]);
      if (!utf8) {
        this.$refs.uploader.reset();
        this.$notify.error({
          message: 'File is not UTF-8 encoded.',
        });
        this.loading = false;
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const body = reader.result;
        const firstLine = body.split('\n').shift();
        const header = firstLine.replace(/"/g, '').split(',');

        if (['location name', 'address', 'city', 'state', 'zip'].every(h => header.includes(h))) {
          this.fileList = fileList;
        } else {
          this.$refs.uploader.reset();
          this.$notify.error({
            message: 'File does not match required format. Please ensure you are using the latest template.',
          });
        }
      };

      papa.parse(fileList[0], {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete: async (results, file) => {
          if (results.data.some(a => a['location name'] === null || a['location name'].trim().length < 1) || results.data.some(a => a.state === null || a.state.trim().length !== 2) || results.data.some(a => a.address === null) || results.data.some(a => a.zip === null || a.zip.toString().length < 3 || !a.zip.toString().match(/(([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])|\d{5})/gi))) {
            this.$notify.error({
              message: 'File does not match required format. Please ensure you are using the latest template.',
            });
            this.$refs.uploader.reset();
            this.fileList = [];

            results.data.filter(a => a['location name'] === null || a['location name'].trim().length < 1).map(a => {
              this.problems.push({ ...a, field: 'location name' });
            });
            results.data.filter(a => a.address === null).map(a => {
              this.problems.push({ ...a, field: 'address' });
            });
            results.data.filter(a => a.state === null || a.state.trim().length !== 2).map(a => {
              this.problems.push({ ...a, field: 'state' });
            });
            results.data.filter(a => a.zip === null || a.zip.toString().length < 3 || !a.zip.toString().match(/(([ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z])|\d{5})/gi)).map(a => {
              this.problems.push({ ...a, field: 'zip' });
            });
            this.showProblems = true;
          }

          if (results.data.length > ADDRESS_LIMIT) {
            this.$notify.error({
              message: `This file is too large, please limit the number of addresses to ${ADDRESS_LIMIT.toLocaleString()} or fewer.`,
            });
            this.$refs.uploader.reset();
            this.fileList = [];
          }
          this.loading = false;
        },
      });
      reader.readAsText(fileList[0], 'UTF-8');
    },

    handleStateChange(isUploading) {
      this.loading = isUploading;
    },

    async handlePlaceRequest() {
      this.loading = true;
      try {
        if (this.fileList.length > 0) {
          await this.$refs.uploader.startUpload({
            name: this.name,
            type: 'AUDIENCE',
            start_date: dateToString(this.dateRange[0]),
            end_date: dateToString(this.dateRange[1]),
            radius: this.radiusOptions[this.radius].meters,
            organization_id: this.organization.id,
            tags: JSON.stringify(this.tags),
          });
          window.mixpanel.track('Commercial Address', {
            Name: this.name,
            Organization: this.organization.name,
            Tags: this.tags,
          });
        }
      } catch (e) {
        this.$reportError(e);
        this.$notify.error({
          message: e.error,
          duration: 7500,
        });
      }

      this.resetForm();
    },

    resetForm() {
      this.$refs.uploader.reset();
      this.loading = false;
      this.organization = this.$store.state.user.orgDetails;
      this.name = '';
      this.dateRange = [];
      this.radius = 1;
      this.tags = [];
      this.files = [];
      this.fileList = [];
    },

    uploadComplete(result) {
      if (!result.status === 'success') {
        return this.$notify.error({
          message: 'Your request was not able to be processed.',
        });
      }

      this.$store.dispatch('mqtt/addRawJob', result.data.job);

      this.$notify.success({
        message: `Processing ${result.data.autopolygon.input_count} addresses.`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  margin: 14px 0;
  label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #666;
    display: block;
  }
  :deep( .el-tag) {
    background: #ddd;
    color: #333;
    margin: 0 5px 5px 0;
  }
  :deep( .el-select) {
    width: 60%;
  }
  :deep( .el-switch) {
    margin-left: 1em;
  }
}
:deep( .el-upload) {
  width: 100% !important;
  .el-upload-dragger {
    width: 100% !important;
  }
}

.bordered {
  margin-top: 1rem;
  padding: 15px 20px;
  border: 1px solid #eee;
  background: #fafafa;
}

.help-text p {
  font-size: 0.85rem;
  pre {
    font-size: 1rem;
    background: #ddd;
    padding: 5px;
    border: 1px solid #666;
  }
}
</style>
